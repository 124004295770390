import { Suspense, lazy } from "react";

import BlogLayout from "./components/blog/BlogLayout";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import DocsLayout from "./components/docs/DocsLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import LoginGuard from "./components/LoginGuard";
import MainLayout from "./components/MainLayout";
import { Navigate } from "react-router-dom";
import PremiumGuard from "./components/PremiumGuard";
import SoonAvailableGuard from "./components/SoonAvailableGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

const Browse = Loadable(lazy(() => import("./pages/browse/Browse")));
const BrowseButtons = Loadable(
  lazy(() => import("./pages/browse/BrowseButtons"))
);
const BrowseCharts = Loadable(
  lazy(() => import("./pages/browse/BrowseCharts"))
);
const BrowseColors = Loadable(
  lazy(() => import("./pages/browse/BrowseColors"))
);
const BrowseDetailLists = Loadable(
  lazy(() => import("./pages/browse/BrowseDetailLists"))
);
const BrowseForms = Loadable(lazy(() => import("./pages/browse/BrowseForms")));
const BrowseGridLists = Loadable(
  lazy(() => import("./pages/browse/BrowseGridLists"))
);
const BrowseGroupedLists = Loadable(
  lazy(() => import("./pages/browse/BrowseGroupedLists"))
);
const BrowseInputs = Loadable(
  lazy(() => import("./pages/browse/BrowseInputs"))
);
const BrowseModals = Loadable(
  lazy(() => import("./pages/browse/BrowseModals"))
);
const BrowseQuickStats = Loadable(
  lazy(() => import("./pages/browse/BrowseQuickStats"))
);
const BrowseTables = Loadable(
  lazy(() => import("./pages/browse/BrowseTables"))
);
const BrowseTypography = Loadable(
  lazy(() => import("./pages/browse/BrowseTypography"))
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

// Blog pages

const BlogPostCreate = Loadable(
  lazy(() => import("./pages/blog/BlogPostCreate"))
);
const BlogPostDetails = Loadable(
  lazy(() => import("./pages/blog/BlogPostDetails"))
);
const BlogPostList = Loadable(lazy(() => import("./pages/blog/BlogPostList")));

// Dashboard pages

const TestComp = Loadable(lazy(() => import("./components/dashboard/account/TestComp")));
const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Calls = Loadable(lazy(() => import("./pages/dashboard/Calls")));
const Messages = Loadable(lazy(() => import("./pages/dashboard/Messages")));
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));
const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
const Chat = Loadable(lazy(() => import("./pages/dashboard/Chat")));
const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/CustomerDetails"))
);
const CustomerEdit = Loadable(
  lazy(() => import("./pages/dashboard/CustomerEdit"))
);
const CustomerList = Loadable(
  lazy(() => import("./pages/dashboard/CustomerList"))
);
const MyDealList = Loadable(lazy(() => import("./pages/dashboard/MyDealList")));
const MyApplicationList = Loadable(
  lazy(() => import("./pages/dashboard/MyApplicationList"))
);
const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));
const InvoiceDetails = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceDetails"))
);
const InvoiceList = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceList"))
);
const Kanban = Loadable(lazy(() => import("./pages/dashboard/Kanban")));
const Mail = Loadable(lazy(() => import("./pages/dashboard/Mail")));
const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const ProductCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProductCreate"))
);
const ProductList = Loadable(
  lazy(() => import("./pages/dashboard/ProductList"))
);

const FollowerPerspective = Loadable(
  lazy(() => import("./pages/dashboard/FollowerPerspective"))
);

// Docs pages

const Docs = Loadable(lazy(() => import("./pages/Docs")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import("./pages/dashboard/ProjectBrowse"))
);
const DealsBrowse = Loadable(
  lazy(() => import("./pages/dashboard/DealsBrowse"))
);
const FAQ = Loadable(lazy(() => import("./pages/dashboard/FAQ")));
const UserQueries = Loadable(lazy(() => import("./pages/dashboard/UserQueries")));
const Privacy = Loadable(lazy(() => import("./pages/dashboard/Privacy")));
const Terms = Loadable(lazy(() => import("./pages/dashboard/Terms")));
const CollaborationsBrowse = Loadable(
  lazy(() => import("./pages/dashboard/CollaborationsBrowse"))
);
const ProjectCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProjectCreate"))
);

const CollaborationCreate = Loadable(
  lazy(() => import("./pages/dashboard/CollaborationCreate"))
);

const EditBrandDealFormLayout = Loadable(
  lazy(() => import("./components/dashboard/project/EditBrandDealFormLayout"))
);

const ProjectDetails = Loadable(
  lazy(() => import("./pages/dashboard/ProjectDetails"))
);
const DealDetails = Loadable(
  lazy(() => import("./pages/dashboard/DealDetails"))
);
const CollaborationDetails = Loadable(
  lazy(() => import("./pages/dashboard/CollaborationDetails"))
);
const ApplicationList = Loadable(
  lazy(() => import("./pages/dashboard/ApplicationList"))
);

const UserMsgPage = Loadable(
  lazy(() => import("./pages/dashboard/UserMsgPage"))
);
const UserUPage = Loadable(lazy(() => import("./pages/dashboard/UserUPage")));

const PageCreator = Loadable(
  lazy(() => import("./pages/dashboard/PageCreator"))
);

const GettingStarted = Loadable(
  lazy(() => import("./pages/dashboard/GettingStarted"))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import("./pages/dashboard/SocialFeed")));
const CommunityFeed = Loadable(
  lazy(() => import("./pages/dashboard/CommunityFeed"))
);
const SocialProfile = Loadable(
  lazy(() => import("./pages/dashboard/SocialProfile"))
);

// Other pages

const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
const Contact = Loadable(lazy(() => import("./pages/Contact")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const Pricing = Loadable(lazy(() => import("./pages/Pricing")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },
  // {
  //   path: "blog",
  //   element: <BlogLayout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: <BlogPostList />,
  //     },
  //     {
  //       path: "new",
  //       element: <BlogPostCreate />,
  //     },
  //     {
  //       path: ":postId",
  //       element: <BlogPostDetails />,
  //     },
  //   ],
  // },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "test",
    element: <TestComp />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "*",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "msg",
        children: [
          {
            path: ":userName",
            element: <UserMsgPage />,
          },
        ],
      },
      {
        path: "start",
        element: <GettingStarted />,
      },
      {
        path: "pagecreator",
        element: < PageCreator/>,
      },
      {
        path: "u",
        children: [
          {
            path: ":instaUserName",
            element: <PageCreator />,
          },
        ],
      },
      {
        path: "feed",
        element: <CommunityFeed />,
      },
      {
        path: "community",
        element: <CommunityFeed />,
      },

      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },

      {
        path: "mydeals",
        element: <MyDealList />,
      },
      {
        path: "myapplications",
        element: <MyApplicationList />,
      },

      {
        path: "userqueries",
        element: <UserQueries />,
      },

      {
        path: "account",
        element: (
          <LoginGuard>
            <Account />
          </LoginGuard>
        ),
      },
      {
        path: "analytics",
        element: (
          <SoonAvailableGuard>
            <Analytics />
          </SoonAvailableGuard>
        ),
      },
      {
        path: "deals",
        element: <DealsBrowse />,
      },
      {
        path: "collaborations",
        element: <CollaborationsBrowse />,
      },
      {
        path: "calendar",
        element: (
          <PremiumGuard>
            <Calendar />
          </PremiumGuard>
        ),
      },
      {
        path: "calls",
        element: (
          <SoonAvailableGuard>
            <Calls />
          </SoonAvailableGuard>
        ),
      },
      {
        path: "messages",
        children: [
          {
            path: "/",
            element: (
              <LoginGuard>
                <Messages />
              </LoginGuard>
            ),
          },
          {
            path: "preview",
            element: (
              <LoginGuard>
                <FollowerPerspective />
              </LoginGuard>
            ),
          },
        ],
      },

      {
        path: "chat",
        children: [
          {
            path: "/",
            element: (
              <PremiumGuard>
                <Chat />
              </PremiumGuard>
            ),
          },
          {
            path: "new",
            element: <Chat />,
          },
          {
            path: ":threadKey",
            element: <Chat />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            path: "/",
            element: <CustomerList />,
          },
          {
            path: ":customerId",
            element: <CustomerDetails />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            path: "/",
            element: <InvoiceList />,
          },
          {
            path: ":invoiceId",
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: "kanban",
        element: (
          <PremiumGuard>
            <Kanban />
          </PremiumGuard>
        ),
      },
      {
        path: "mail",
        children: [
          {
            path: "/",
            element: <Navigate to="/mail/all" replace />,
          },
          {
            path: "label/:customLabel",
            element: <Mail />,
          },
          {
            path: "label/:customLabel/:emailId",
            element: <Mail />,
          },
          {
            path: ":systemLabel",
            element: <Mail />,
          },
          {
            path: ":systemLabel/:emailId",
            element: <Mail />,
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "/",
            element: <OrderList />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "finance",
        element: <Finance />,
      },
      {
        path: "products",
        children: [
          {
            path: "/",
            element: <ProductList />,
          },
          {
            path: "new",
            element: <ProductCreate />,
          },
        ],
      },
      {
        path: "projects",
        children: [
          {
            path: "browse",
            element: <ProjectBrowse />,
          },
          {
            path: "new",
            element: <ProjectCreate />,
          },
          {
            path: ":projectId",
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: "deal",
        children: [
          {
            path: ":dealId",
            children: [
              {
                path: "/",
                element: <DealDetails />,
              },
              {
                path: "edit",
                element: (
                  <LoginGuard>
                    <EditBrandDealFormLayout />,
                  </LoginGuard>
                ),
              },
              {
                path: "applications",
                element: (
                  <LoginGuard>
                    <ApplicationList />
                  </LoginGuard>
                ),
              },
            ],
          },
          {
            path: "new",
            element: <ProjectCreate />,
          },
        ],
      },
      {
        path: "collaboration",
        children: [
          {
            path: ":collaborationId",
            children: [
              {
                path: "/",
                element: <CollaborationDetails />,
              },
              {
                path: "edit",
                element: (
                  <LoginGuard>
                    <EditBrandDealFormLayout />,
                  </LoginGuard>
                ),
              },
              {
                path: "applications",
                element: (
                  <LoginGuard>
                    <ApplicationList />
                  </LoginGuard>
                ),
              },
            ],
          },
          {
            path: "new",
            element: <CollaborationCreate />,
          },
        ],
      },

      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeed />,
          },
          {
            path: "profile",
            element: (
              <LoginGuard>
                <SocialProfile />
              </LoginGuard>
            ),
          },
        ],
      },
      {
        path: "blog",
        element: <BlogLayout />,
        children: [
          {
            path: "/",
            element: <BlogPostList />,
          },
          {
            path: "new",
            element: <BlogPostCreate />,
          },
          {
            path: ":postId",
            element: <BlogPostDetails />,
          },
        ],
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "docs",
    element: <DocsLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: "*",
        element: <Docs />,
      },
    ],
  },
  {
    path: "browse",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },

      {
        path: "/mainLayout",
        element: <Browse />,
      },
      {
        path: "/buttons",
        element: <BrowseButtons />,
      },
      {
        path: "/inputs",
        element: <BrowseInputs />,
      },
      {
        path: "/charts",
        element: <BrowseCharts />,
      },
      {
        path: "/colors",
        element: <BrowseColors />,
      },
      {
        path: "/data-display/detail-lists",
        element: <BrowseDetailLists />,
      },
      {
        path: "/data-display/quick-stats",
        element: <BrowseQuickStats />,
      },
      {
        path: "/data-display/tables",
        element: <BrowseTables />,
      },
      {
        path: "/forms",
        element: <BrowseForms />,
      },
      {
        path: "/modals",
        element: <BrowseModals />,
      },
      {
        path: "/lists/grouped-lists",
        element: <BrowseGroupedLists />,
      },
      {
        path: "/lists/grid-lists",
        element: <BrowseGridLists />,
      },
      {
        path: "/typography",
        element: <BrowseTypography />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "500",
    element: <ServerError />,
  },
];

export default routes;
