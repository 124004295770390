import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { getUserStorageSubDocFS, writeDBData } from "internal/DBFunctions";
import { subDays, subHours } from "date-fns";
import { useEffect, useRef, useState } from "react";

import BellIcon from "../../icons/Bell";
import BookIcon from "@material-ui/icons/Book";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { Link as RouterLink } from "react-router-dom";
import ShoppingCartIcon from "../../icons/ShoppingCart";
import { analytics_logUseFunctionality } from "internal/AnalyticsLib";
import { setUserStorageSubDocFS } from "internal/DBFunctions";
import useAuth from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  unreadStyle: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  readStyle: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const now = new Date();

const defaultNotificationsLoggedOut = [
  {
    id: "5e8883f1b51cc1956a5a1ec0",
    createdAt: subHours(now, 2).getTime(),
    description: "We found a new matching brand deal for you",
    title: "New matching brand deal",
    type: "matching_brand_deal",
    status: "unread",
  },
  {
    id: "5e8883f7ed1486d665d8be1e",
    createdAt: subDays(now, 1).getTime(),
    description: "Your deal application just got accepted",
    title: "Deal Application accepted",
    type: "application_accepted",
    status: "unread",
  },
  {
    id: "5e8883fca0e861204424123cf",
    createdAt: subDays(now, 5).getTime(),
    description: "See the latest post in the feed",
    title: "New Community Post",
    type: "feed_interaction",
    status: "unread",
  },
];

// Determine title from type
const iconsMap = (type) => {
  switch (type) {
    case "new_application":
      return BellIcon;
    case "matching_brand_deal":
      return ShoppingCartIcon;
    case "application_declined":
      return CancelIcon;
    case "application_accepted":
      return CheckCircleIcon;
    case "feed_interaction":
      return EmojiPeopleIcon;
    case "general_information":
      return BellIcon;
    case "special_subscription":
      return ShoppingCartIcon;
    case "new_blog_article":
      return BookIcon;
    case "feedback_required":
      return NotificationImportantIcon;
    default:
      return BellIcon;
  }
};

// Todo: define msg types: dealId sollte direkt geladen werden
// Also Parameter für die Nachricht
// Link in Nachricht?
// Breaking API, wenn neue Nachricht auf alte Version trifft?
const iconsLink = (type) => {
  switch (type) {
    case "new_application":
      return "/mydeals";
    case "matching_brand_deal":
      return "/deals";
    case "application_declined":
      return "/myapplications";
    case "application_accepted":
      return "/myapplications";
    case "feed_interaction":
      return "/feed";
    case "general_information":
      return "/";
    case "special_subscription":
      return "/account#subscription";
    case "new_blog_article":
      return "/blog";
    case "feedback_required":
      return "/mydeals";
    default:
      return "/";
  }
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const auth = useAuth();

  const [Notifications, setNotifications] = useState(
    defaultNotificationsLoggedOut
  );

  useEffect(async () => {
    if (auth.isAuthenticated) {
      getUserStorageSubDocFS(
        "Notifications",
        setNotifications,
        defaultNotificationsLoggedOut
      );
    } else {
      setNotifications(defaultNotificationsLoggedOut);
    }
  }, [auth]);

  const handleOpen = () => {
    analytics_logUseFunctionality("Notifications", { info: "Opened" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const archiveAll = async () => {
    for (var notification of Notifications) {
      notification["status"] = "archived";
    }

    await setNotifications(Notifications);
    await uploadTable(Notifications);

    handleClose();
  };

  const readMessage = async (notificationId) => {
    for (var notification of Notifications) {
      if (notification.id == notificationId) notification["status"] = "read";
    }

    await setNotifications(Notifications);
    await uploadTable(Notifications);

    handleClose();
  };

  // This could be buggy. Is this check really required?
  const uploadTable = async (modifiedNotifications) => {
    return new Promise(async (resolve, reject) => {
      // condition added recently - maybe working now. But requires intense testing. Maybe rewriting.
      if (
        auth.isAuthenticated &&
        Notifications != null &&
        Notifications != defaultNotificationsLoggedOut
      ) {
        setUserStorageSubDocFS(modifiedNotifications, "Notifications");
      }
      resolve(true);
    });
  };

  // UI
  const getUnreadMsg = () => {
    let unreadMsgs = 0;
    Notifications.forEach((notification) => {
      if (notification.status == "unread") unreadMsgs = unreadMsgs + 1;
    });
    return unreadMsgs;
  };

  const getVisibleMsg = () => {
    let visibleMsgs = 0;
    Notifications.forEach((notification) => {
      if (notification.status != "archived") visibleMsgs = visibleMsgs + 1;
    });
    return visibleMsgs;
  };

  const testfunc = () => {
    console.log(Notifications);
    Notifications.forEach((notification) => {});
  };

  const classes = useStyles();

  return (
    <>
      {/* <Button onClick={testfunc}>testfunc</Button> */}
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={getUnreadMsg()}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        {getVisibleMsg() === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              There are currently no notifications for you
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {Notifications.map((notification) => {
                const Icon = iconsMap(notification.type);

                if (notification.status != "archived") {
                  return (
                    <ListItem
                      onClick={() => readMessage(notification.id)}
                      component={RouterLink}
                      to={
                        notification.path == null
                          ? iconsLink(notification.type)
                          : notification.path
                      }
                      divider
                      key={notification.id}
                      className={
                        notification.status == "unread"
                          ? classes.unreadStyle
                          : classes.readStyle
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        component={RouterLink}
                        to="/deals"
                        primary={
                          <Link
                            color="textPrimary"
                            sx={{ cursor: "pointer" }}
                            underline="none"
                            variant="subtitle2"
                          >
                            {notification.title}
                          </Link>
                        }
                        secondary={notification.description}
                      />
                    </ListItem>
                  );
                }
              })}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                onClick={archiveAll}
                color="primary"
                size="small"
                variant="text"
              >
                Archive all
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
