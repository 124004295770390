import {
  addDays,
  endOfDay,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
} from "date-fns";
import { getUserID, validateUser } from "./Checks";
import { readDBDataWithUid, writeDBDataWithUid } from "internal/DBFunctions";

import { NotificationsOnly } from "./FeatureEnabling";
import { apiURL } from "./FixedStrings";
import { createUUID } from "./LayoutFunctions";
import { debugging } from "./NotCheckinStrings";
import { firestore } from "lib/firebase";
import { getCurrentDate } from "internal/VisuElements.js";
import { writeLog } from "internal/DBFunctions";

const now = new Date();

//// Mails ////
export const writeAdminMail = async (subject, text) => {
  var adminMail = "admin@creatordash.net";
  await writeMail(adminMail, subject, text);
  return true;
};

export const writeMail = (recipient, subject, text) => {
  firestore.collection("mail").add({
    to: recipient,
    message: {
      subject: subject,
      text: text,
      html: text,
    },
  });
  return true;
};

export const sendTransactionalMailAccepted = (
  recipientMail,
  businessMail,
  dealTitle,
  recipientUid
) => {
  if (recipientUid != null) {
    let apiCall = "/api/writeNotification";
    let requestData = {
      title: "Application Accepted",
      description: "Your application of " + dealTitle + " has been accepted",
      type: "application_accepted",
      recipient_uid: recipientUid
    };

    const requestOptionsURL = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(apiURL + apiCall, requestOptionsURL)
      .then(function (response) {
        return response.json();
      });

  } else {
    var templateParams = { businessMail: businessMail, dealTitle: dealTitle };

    var requestData = {
      recipientMail: recipientMail,
      templateParams: templateParams,
      templateId: 3,
    };

    sendMailWithAPI(requestData);
  }
};

export const sendTransactionalMailDeclined = (
  recipientMail,
  dealTitle,
  recipientUid
) => {
  if (recipientUid != null) {
    let apiCall = "/api/writeNotification";
    let requestData = {
      title: "Application Declined",
      description: "Your application of " + dealTitle + " has been declined",
      type: "application_declined",
      recipient_uid: recipientUid
    };

    const requestOptionsURL = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(apiURL + apiCall, requestOptionsURL)
      .then(function (response) {
        return response.json();
      });

  } else {
    var templateParams = { dealTitle: dealTitle };

    var requestData = {
      recipientMail: recipientMail,
      templateParams: templateParams,
      templateId: 4,
    };

    sendMailWithAPI(requestData);
  }
};

// Currently unused
export const sendTransactionalApplicationReceived = (
  recipientMail,
  dealTitle
) => {
  var templateParams = { dealTitle: dealTitle };

  var requestData = {
    recipientMail: recipientMail,
    templateParams: templateParams,
    templateId: 10,
  };

  sendMailWithAPI(requestData);
};

export const sendTransactionalNewDealApplication = (
  recipientMail,
  dealTitle,
  recipientUid
) => {
  if (recipientUid != null) {
    // Call server api to write a notification into the deals owner/uid notifications
    let apiCall = "/api/writeNotification";
    let requestData = {
      title: "New Deal Application",
      description: "You got an application from " + recipientMail,
      type: "new_application",
      recipient_uid: recipientUid
    };

    const requestOptionsURL = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(apiURL + apiCall, requestOptionsURL)
      .then(function (response) {
        return response.json();
      });

  } else {
    // Old version without a uid in the deals
    var templateParams = { dealTitle: dealTitle };

    var requestData = {
      recipientMail: recipientMail,
      templateParams: templateParams,
      templateId: 6,
    };
    sendMailWithAPI(requestData);
  }
};

export const sendMailWithAPI = async (requestData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };

  var validUser = await validateUser();

  if (!validUser) {
    return false;
  }

  if (!debugging) {
    if (!NotificationsOnly) {
      fetch(apiURL + "/api/sendMail", requestOptions).then(function (response) {
        let result = response.json();
        return result;
      });
    }
  } else {
    console.log("I would send a mail now");
    // writeLog({ requestData: requestData }, "new_mail");
  }
};

//// Notifications ////
export const newNotification = (title, description, type, path = null) => {
  var newNotification = {
    id: createUUID(),
    createdAt: subDays(now, 0).getTime(),
    description: description,
    title: title,
    type: type,
    status: "unread",
    fcm: "unnotified",
    path: path,
  };
  return newNotification;
};

export const writeNotification = (title, description, type, recipient_uid) => {
  return new Promise(async (resolve, reject) => {
    var validUser = await validateUser();

    if (!validUser) {
      return false;
    }

    // if (!debugging) {
    // First read the data and then append list
    readDBDataWithUid("Notifications", recipient_uid).then(async (doc_data) => {
      console.log(doc_data);
      var notificationList = [];
      if (doc_data != null) {
        notificationList = doc_data;
      }

      var newNotificationMsg = await newNotification(title, description, type);
      notificationList.push(newNotificationMsg);

      writeDBDataWithUid(
        "Notifications",
        notificationList,
        recipient_uid
      );
      resolve(true);
    });
    // } else {
    //   var requestData = { title, description, type, recipient_uid };
    //   writeLog({ requestData: requestData }, "new_notification");
    // }
  });
};
