import { auth } from "lib/firebase";
import { readDBData, writeDBData } from "internal/DBFunctions";
import { readGlobalDoc, writeLog } from "./DBFunctions";
import {
  analytics_logError,
  analytics_logEvent,
  analytics_logUsageConversion,
} from "./AnalyticsLib";
import { getSecuritydocRef } from "./DBLocations";
import { firestore } from "lib/firebase";
import { blockedAfterMessageOccurence } from "./FeatureSettings";

// const BitlyClient = require("bitly").BitlyClient;
//todo: Hide key
// const bitly = new BitlyClient("10f3147740e04fd0ea4c68788a84147cc6034dfa");

// Get user_id -> checkUser -> LoginAlert
export const checkUser = () => {
  return auth.currentUser != null;
};

export const getUser = () => {
  if (checkUser()) {
    return auth.currentUser;
  }
  return null;
};

export const getUserID = () => {
  if (checkUser()) {
    return auth.currentUser.uid;
  }
  return null;
};

export const getUserEmail = () => {
  if (checkUser()) {
    return auth.currentUser.email;
  }
  return null;
};

export const getMemberShip = async () => {
  if (checkUser()) {
    var UserData = await readDBData("UserData");
    return UserData.membership;
  }
  return null;
};

export const validateUser = async (email = null) => {
  return new Promise(async (resolve, reject) => {
    var returnValue = true;
    var response = await readGlobalDoc("securityDoc");

    var UserData = await readDBData("UserData").then((doc_data) => {
      return doc_data;
    });

    if (UserData == null && email == null) {
      // Return true
      resolve(returnValue);
    }

    var emailToAnalyze = email ? email : UserData.email;

    var blockedDomains = response["data"].blockedDomains;
    blockedDomains.forEach((blockedDomain) => {
      if (emailToAnalyze.endsWith(blockedDomain)) {
        returnValue = false;
      }
    });

    var blockedEMails = response["data"].blockedEMails;
    blockedEMails.forEach((blockedEMail) => {
      if (emailToAnalyze == blockedEMail) {
        returnValue = false;
      }
    });

    // Logging
    if (returnValue == false) {
      analytics_logError("Blocked_user_operation", {
        emailToAnalyze: emailToAnalyze,
      });
    }

    resolve(returnValue);
  });
};

export const validateApplicationMessage = async (motivation, email) => {
  return new Promise(async (resolve, reject) => {
    var returnValue = true;
    var response = await readGlobalDoc("applications");

    var allApplications = response["data"];
    var occurrence = 0;
    allApplications.forEach((application) => {
      if (motivation != null && application.motivation == motivation) {
        occurrence = occurrence + 1;
      }
    });

    if (occurrence >= blockedAfterMessageOccurence) {
      // Block user because of spam
      await blockMail(email);
      returnValue = false;
    }

    // Logging
    if (returnValue == false) {
      analytics_logError("EMail_blocked", {
        email: email,
      });
    }

    resolve(returnValue);
  });
};

export const blockMail = (email) => {
  return new Promise(async (resolve, reject) => {
    var docRef = getSecuritydocRef();

    return firestore
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(docRef).then((transactionDoc) => {
          if (!transactionDoc.exists) {
            throw "Document does not exist!";
          }

          var securityDocData = transactionDoc.data().data;

          securityDocData.blockedMails.push(email);

          transaction.set(docRef, { data: securityDocData });
          analytics_logUsageConversion("Blocked_Mail", { email: email });
        });
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log("Transaction failed: ", error);
        writeLog(
          {
            email: email,
            error: JSON.stringify(error),
          },
          "Document Failed to update",
          true
        );
        reject(false);
      });
  });
};

export const getShortLink = async (property, withouthttps = false) => {
  var longLink =
    "https://app.spexdoc.net/" + property + "/publicKey=" + getUserID();
  // const response = await bitly.shorten(longLink);
  const response = ""; // remove

  var link = response.link;
  if (withouthttps) {
    var https = "https://";
    if (link.includes(https)) {
      var link_without_https_length = link.indexOf(https); // 38

      var link_without_https = link.substr(
        link_without_https_length + https.length
      );

      link = link_without_https;
    }
  }
  return link;
};

export const removehttpsFromLink = (link) => {
  var https = "https://";
  if (link.includes(https)) {
    var link_without_https_length = link.indexOf(https); // 38

    var link_without_https = link.substr(
      link_without_https_length + https.length
    );
    return link_without_https;
  } else {
    console.log("error: no https given");
    return link;
  }
};
